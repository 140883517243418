import React, { useState } from 'react';
import './index.css';  // Use this for styling your component

// Define the possible languages and their content types
type Language = 'es' | 'en';

const Maintenance = () => {
  // Language state
  const [language, setLanguage] = useState<Language>('es'); // Default to Spanish ('es')

  // Handle language change
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value as Language); // Cast the value to the 'Language' type
  };

  const handleAccessClick = () => {
    window.location.href = 'https://irportal.qualitasenergy.com/login';  // Replace with your desired URL
  };

  // Dynamic content based on language
  const content: Record<Language, { title: string, assistance: string, email: string, button: string }> = {
    es: {
      title: 'Le damos la bienvenida al nuevo Portal del Inversor',
      assistance: 'Si necesita asistencia, por favor, contacte con el equipo de Relación con Inversores en',
      email: 'investor.relations@qenergy.com',
      button: 'Acceder'
    },
    en: {
      title: 'Welcome to the new Investor Portal',
      assistance: 'If you need assistance, please contact the Investor Relations team at',
      email: 'investor.relations@qenergy.com',
      button: 'Access'
    }
  };

  return (
    <div className='maintenance-container'>
      <div className='content'>
        {/* Language Selector */}
        <div className='language-selector'>
          <select value={language} onChange={handleLanguageChange}>
            <option value="es">Español</option>
            <option value="en">English</option>
          </select>
        </div>

        <img src={require("../../Assets/images/logo_QE.png")} alt="Qualitas Energy" className="logo" />
        <h1 className="custom-title">{content[language].title}</h1>
        <p>
          {content[language].assistance} <br />
          <a href="mailto:Investor.relations@qenergy.com">{content[language].email}</a>
        </p>
        <button className="access-button" onClick={handleAccessClick}>{content[language].button}</button>
      </div>
    </div>
  );
};

export default Maintenance;
