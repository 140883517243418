import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PortalInversor from '../src/App';
import AdminUsuarios from '../src/components/AdminUsuarios/AdminUsuarios';
import LoginUsuario from '../src/components/Login/Login';
import Maintenance from '../src/components/Maintenance';
import RecuperarPassword from '../src/components/Login/recuperarPassword';
import CrearUsuariosTemp from './components/AdminUsuarios/CrearUsuarios/CrearUsuarios';
import DataRoom from './components/DataRoom/HomeDataRoom/HomeDataRoom';
import SessionCookieGenerator from './components/SessionCookieGenerator/SessionCookieGenerator';
import { Utils } from './utils/Utils';
import { MaintenanceService } from './Servicios/MaintenanceService';

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);

  // Maintenance check logic
  const page = () =>
    new MaintenanceService().obtenerValorGenericaMantenimiento({
      code: 'page_mante',
    });

  useEffect(() => {
    const checkMaintenance = async () => {
      let mante = await page();
      if (mante.result.Value === 'true') {
        setIsMaintenance(true);
      } else {
        setIsMaintenance(false);
      }
    };
    checkMaintenance();
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          {/* Show Maintenance page if portal is in maintenance mode */}
          {isMaintenance ? (
            <Route component={Maintenance} />
          ) : (
            <>
              <Route exact path="/" component={PortalInversor} />
              <Route
                path={['/login', '/es/:app?', '/en/:app?', '/de/:app?']}
                component={LoginUsuario}
              />
              <Route path="/maintenance" component={Maintenance} />
              <Route path="/AdminUsuarios" component={AdminUsuarios} />
              <Route path="/recuperar" component={RecuperarPassword} />
              <Route path="/DataRoom" component={DataRoom} />
              <Route
                exact
                path="/verDoc"
                render={() => {
                  window.location.href = './Assets/viewerjs/web/viewer.html';
                }}
              />
              <Route path="/CrearUsuariosTemp" component={CrearUsuariosTemp} />
              <Route
                path="/SessionCookieGenerator/:token?/:app?/:lang?"
                component={SessionCookieGenerator}
              />
            </>
          )}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
